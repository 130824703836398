var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "header-card" }, [
        _c("div", { staticClass: "d-flex flex-row justify-content-between" }, [
          _c("div", { staticClass: "summary-card-header-txt pl-2" }, [
            _vm._v(" " + _vm._s(_vm.title) + " "),
          ]),
          _c(
            "div",
            {
              staticClass: "avo-text-light pr-2 mt-1 cursor-pointer",
              on: {
                click: function ($event) {
                  return _vm.setActiveTab("WorkClaimActivity")
                },
              },
            },
            [
              _vm._v(" View all Activity "),
              _c("b-icon", { attrs: { icon: "chevron-right" } }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "b-overlay",
        {
          class: _vm.eventsLoading ? "events" : "",
          staticStyle: { "min-height": "200px" },
          attrs: { show: _vm.eventsLoading, variant: "white" },
        },
        [
          _c("b-table", {
            ref: "table",
            staticClass: "events",
            attrs: {
              id: "my-table",
              "sort-desc": _vm.sortDesc,
              "per-page": _vm.perPage,
              "current-page": _vm.currentPage,
              items: _vm.events,
              fields: _vm.eventsFields,
              borderless: _vm.borderless,
              "thead-class": "d-none",
              "primary-key": "event_id",
            },
            on: {
              "update:sortDesc": function ($event) {
                _vm.sortDesc = $event
              },
              "update:sort-desc": function ($event) {
                _vm.sortDesc = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "cell(main-col)",
                fn: function (data) {
                  return [
                    _c("div", { staticClass: "p-2 pr-0" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-row align-items-center" },
                        [
                          _c(
                            "div",
                            { staticClass: "mr-4" },
                            [
                              _c(
                                "b-iconstack",
                                [
                                  _c("b-icon", {
                                    staticStyle: { color: "#F3F8FD" },
                                    attrs: { icon: "circle-fill", scale: "2" },
                                  }),
                                  _vm.getEventIconType(data.item) == "Document"
                                    ? _c("b-icon", {
                                        attrs: { icon: "paperclip" },
                                      })
                                    : _vm.getEventIconType(data.item) == "Note"
                                    ? _c("b-icon", {
                                        attrs: { icon: "file-earmark" },
                                      })
                                    : _vm.getEventIconType(data.item) == "Task"
                                    ? _c("b-icon", {
                                        attrs: { icon: "calendar-4" },
                                      })
                                    : _vm.getEventIconType(data.item) ==
                                      "Worklog"
                                    ? _c("b-icon", {
                                        attrs: { icon: "list-ul" },
                                      })
                                    : _vm.getEventIconType(data.item) ==
                                      "Worker"
                                    ? _c("b-icon", {
                                        attrs: { icon: "person" },
                                      })
                                    : _vm.getEventIconType(data.item) == "Claim"
                                    ? _c("b-icon", {
                                        attrs: { icon: "file-earmark-medical" },
                                      })
                                    : _vm.getEventIconType(data.item) == "Code"
                                    ? _c("b-icon", {
                                        attrs: { icon: "card-list" },
                                      })
                                    : _vm.getEventIconType(data.item) ==
                                      "Payment"
                                    ? _c("b-icon", {
                                        attrs: { icon: "credit-card" },
                                      })
                                    : _vm.getEventIconType(data.item) ==
                                      "Flagged"
                                    ? _c("b-icon", {
                                        attrs: { icon: "exclamation-triangle" },
                                      })
                                    : _vm.getEventIconType(data.item) ==
                                      "Transition"
                                    ? _c("b-icon", {
                                        attrs: { icon: "arrow-left-right" },
                                      })
                                    : _c("b-icon", {
                                        attrs: { icon: "list-ul" },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "label-text",
                                staticStyle: {
                                  "font-size": "16px",
                                  "font-weight": "400",
                                },
                              },
                              [
                                _vm._v(" " + _vm._s(data.item.label)),
                                data.item.payload.title
                                  ? _c("span", [
                                      _vm._v(": "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "label-text",
                                          staticStyle: { "font-size": "16px" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(data.item.payload.title)
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c("div", { staticClass: "avo-text-light" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatDate(data.item.occurred_at)
                                  ) +
                                  " at " +
                                  _vm._s(
                                    _vm.formatTime(data.item.occurred_at)
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-column align-items-end flex-wrap",
                      },
                      [
                        _c("div", { staticClass: "text-nowrap" }, [
                          _vm._v("by " + _vm._s(data.item.name)),
                        ]),
                        _c("div", { staticClass: "avo-text-light" }, [
                          _vm._v(
                            _vm._s(
                              data.item.user_role
                                ? data.item.user_role
                                : "Worker"
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm.events.length == 0 && _vm.eventsLoading
            ? _c("div", { staticClass: "no-events" }, [
                _vm._v(" Fetching events "),
              ])
            : _vm._e(),
          _vm.events.length == 0 && !_vm.eventsLoading
            ? _c("div", { staticClass: "no-events" }, [
                _vm._v(" No events found "),
              ])
            : _vm._e(),
          !_vm.hideFooter
            ? _c(
                "div",
                { staticClass: "footer-text" },
                [
                  _c("b-link", { attrs: { href: "#todo" } }, [
                    _vm._v("View All Activity"),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }