var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.events
    ? _c(
        "div",
        [
          _vm._m(0),
          _c("hr", { staticClass: "w-100 mb-0 mt-1" }),
          _c(
            "b-overlay",
            {
              staticStyle: { "min-height": "200px" },
              attrs: { show: _vm.eventsLoading, variant: "white" },
            },
            [
              _c("b-table", {
                ref: "table",
                staticClass: "events",
                attrs: {
                  id: "my-table",
                  "sort-desc": _vm.sortDesc,
                  "per-page": _vm.perPage,
                  "current-page": _vm.currentPage,
                  items: _vm.events,
                  fields: _vm.eventsFields,
                  borderless: _vm.borderless,
                  "thead-class": "d-none",
                  "primary-key": "event_id",
                },
                on: {
                  "update:sortDesc": function ($event) {
                    _vm.sortDesc = $event
                  },
                  "update:sort-desc": function ($event) {
                    _vm.sortDesc = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(initials)",
                      fn: function (data) {
                        return [_c("b-avatar", { attrs: { text: data.value } })]
                      },
                    },
                    {
                      key: "cell(label)",
                      fn: function (data) {
                        return [
                          _c("div", { staticClass: "user-text" }, [
                            _vm._v(" " + _vm._s(data.item.name)),
                          ]),
                          _c("div", { staticClass: "event-text" }, [
                            _vm._v(
                              _vm._s(data.value) +
                                " on " +
                                _vm._s(
                                  new Date(
                                    data.item.occurred_at
                                  ).toLocaleString("en-US", {
                                    month: "numeric",
                                    day: "numeric",
                                    year: "numeric",
                                  })
                                ) +
                                "."
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(occurred_at)",
                      fn: function (data) {
                        return [
                          _c("div", { staticClass: "event-text" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  new Date(data.value)
                                    .toLocaleString("en-US", {
                                      month: "numeric",
                                      day: "numeric",
                                      year: "numeric",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })
                                    .replace(",", " at")
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  995291197
                ),
              }),
              _vm.events.length == 0 && !_vm.eventsLoading
                ? _c("div", { staticClass: "no-events pt-5" }, [
                    _vm._v(" No events for this claim. "),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "nav mt-3 mb-2" }, [
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link active disabled label-text pl-4 pt-0",
            staticStyle: {
              "text-decoration": "underline var(--blue) 3px",
              "text-underline-offset": "1.2rem",
            },
            attrs: { href: "#" },
          },
          [_vm._v("Activity")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }